<template>
  <v-card class="elevation-1">
    <v-card-title>
      Bank Report
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
    <template #[`item.amount`]="{ item }">
      {{Intl.NumberFormat().format(item.amount)}}
    </template>
    </v-data-table>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFilePdfBoxOutline } from '@mdi/js'
import getData from '@/composables/getData'
import blob from '@/composables/blob'
import snackbar from '@/components/SnackBar.vue'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
  },

  props: ['formData'],

  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: '', value: 'actions' },
      { text: 'Account Number', align: 'start', value: 'bank_account' },
      { text: 'Amount', value: 'amount' },
      { text: 'Name', value: 'name' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const icon = {
      payslip: mdiFilePdfBoxOutline,
    }
    const reportData = ref('')
    const error = ref('')
    const message = ref('')
    const messageStatus = ref('')
    const uri = ref(`${process.env.VUE_APP_URI}/api/payroll/reports/debit-advise`)

    watch(options, () => {
      getData(uri.value, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(uri.value, items, totalItems, loading, options, search)
    })

    watch(() => props.formData, val => {
      items.value = []
      totalItems.value = 0
      options.value.page = 1

      uri.value = `${process.env.VUE_APP_URI}/api/payroll/reports/debit-advise/${fnx.base64_encode(val)}`
      getData(uri.value, items, totalItems, loading, options, search)
    })

    const viewPayslip = async item => {
      const mimetype = 'application/pdf'
      await blob(`${process.env.VUE_APP_URI}/api/payroll/reports/payslip/${item.unique_id}`, reportData, null, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      } else {
        console.log(reportData.value)
        const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
        window.open(url, '_blank')
      }
    }

    return {
      message,
      messageStatus,
      search,
      headers,
      items,
      searchTable,
      icon,
      options,
      totalItems,
      loading,
      viewPayslip,
    }
  },
}
</script>
